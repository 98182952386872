.shared-guide-preview-comment-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    right: 0.8%;

    .mt-45 {
         margin-top: 45px;
    }
    .mt-70 {
        margin-top: 70px;
    }
    .preview-comment-box {
        width: 250px;
        height: fit-content;
        padding: 10px;
        background: white;
        box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1);
        border-radius: 12px;

        .frequentlyAskedBox {
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: #FFF4E0;
            border-radius: 3px;
            padding: 6px 10px 6px 6px;

            .frequentlyAskedText {
                font-family: 'Poppins';
                font-size: 11px;
                font-weight: 500;
                line-height: 14px;
                letter-spacing: 0.005em;
                text-align: left;
                color: #3D3D3D;
                margin-left: 4px;
                padding-right: 6px;
            }
        }

        .questionBox {
            margin-top: 10px;
            font-family: 'Poppins';
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0.025em;
            text-align: left;
            color:  #3D3D3D;
        }

        .text-1 {
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 12px;
            letter-spacing: 0.025em;
            color: #3D3D3D;
        }

        .text-3
        {
            font-family: 'Poppins';
            font-weight: 500;
            font-size: 12px;
            letter-spacing: 0.025em;
            color: #0D399A;
        }
        .word-break
        {
            word-break: break-word;
        }

        .user-details {
           width:71%;
           word-wrap: break-word;

            .boatHeadingBox {
                 display: flex;
                 flex-direction: row;
                 align-items: center;
                 margin-top: -9px;

                 .boatHeadingText {
                    font-family: 'Poppins';
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 14px;
                    letter-spacing: 0.025em;
                    text-align: left;
                    color: #123FBC;
                    margin-left: 3px;
                 }
            }


            .text-2 {
                font-family: 'Poppins';
                font-weight: 400;
                font-size: 10px;
                letter-spacing: 0.025em;
                color: #5A5A5A;
            }
        }

        .MuiOutlinedInput-notchedOutline {
            outline: none;
            border: none;
        }

        .reply-input {
            width: 100%;
            min-height: 40px;
            max-height: fit-content;
            border: 1px solid #C2C2C2;
            border-radius: 8px;
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 12px;
            letter-spacing: 0.025em;
            color: #3D3D3D;
            display: flex;
            justify-content: center;
        }

        .reply-input input::placeholder {
            font-family: 'Poppins';
            font-size: 12px;
            font-weight: 400;
            color: #808080;
        }

        .submit-reply-btn {
            width: 110px;
            height: 34px;
            border: 1px solid #C9C9C9;
            border-radius: 8px;
            background: #ffffff;
            color: #808080;
            font-family: 'Poppins';
            font-weight: 500;
            font-size: 13px;
            letter-spacing: 0.025em;
            text-transform: uppercase;
            text-align: center;
        }

        .submit-reply-btn:hover {
            background: #ffffff;
            color: #808080;
        }

        .comments-divider {
            border: 0.4px solid #E1E1E1;
            margin: 10px auto;
        }

        .options-box {
            background: white;
            align-items: center;
            width: fit-content;
            position: absolute;
            right: 30px;
            margin-top: 40px;
            border: 1px solid rgba(0, 0, 0, 0.125);
            border-radius: 4px;
            box-shadow: 1px 2px 25px rgba(0, 0, 0, 0.1);
            z-index: 5;

            .list-1:hover {

                color: white;
                cursor: pointer;
                background: #2946ea;
            }

            .red-bg {
                color: white;
                cursor: pointer;
                background: #be0d0d;
                padding: 5px 20px;
                text-transform: capitalize;
            }
            .disabled-btn
            {
                color: white;
                background: #838383;
            }
        }
        .options-box-2
        {
            margin-top: 0px;
        }
    }
}

.removePositionProperty {
    position: static;
    margin-left: 0px !important;
}