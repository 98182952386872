.shared-guide-image-reference-modal {
    .guide-image-nav {
        background: #525659;
        height: 50px;
        padding: 20px;

        .image-label {
            font-family: Poppins;
            font-weight: 600;
            font-size: 20px;
            letter-spacing: 0.025em;
            color: #FFFFFF;
            margin-right: 17px;
        }

        .close-icon {
            width: 17.4px;
            height: 17.4px;
            object-fit: contain;
            cursor: pointer;
        }
    }
}
