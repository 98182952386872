.report-section-parent{
    min-height: 600px;
    background: #FFFFFF;
    box-shadow: -4px -5px 54px -17px rgba(0, 0, 0, 0.13);
    border-radius: 14px;
    padding-top: 20px;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 50px;
    min-width: 900px;

    .common-text{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 124.4%;   
        letter-spacing: 0.005em;
        color: #A3A3A3;
    }

    .quill-box {
        line-break: auto;
        word-break: break-word;
        display: block;
        img{
            max-height: 400px;
            max-width: 60%;
            margin: 6px;
            display: block;
        }
        p{
            line-break: auto;
            word-break: break-word;
            margin-bottom: 0.2rem;
        }
    }


    .report-header{
        height:170px;

        .margin-top-5{
            margin-top: 5px;
        }

        .stats-text{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 124.4%;
            letter-spacing: 0.005em;
            color: #3D3D3D;
        }


        .switch-tab{
            border-radius: 14px;
            padding: 5px;
            background: #FFD6D3;

            .text{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 124.4%;
                letter-spacing: 0.005em;
                color: #3D3D3D;
                white-space:nowrap;
            }

            .value{
               font-weight: 600;
            }
        }

        .bold-text {
            font-weight: 500;
            font-size: 13px;
            line-height: 124.4%;
            letter-spacing: 0.005em;
            color: #3D3D3D;
        }

        .bg-green{
            background: #E8F9E7;
        }
        .bg-red {
            background: #FBECEC;
        }

        .horizontal-container{
            display:flex;
            flex-direction: row;
            margin-top: 20px;
            align-items: center;

             .start-time-text{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 124.4%;     
                letter-spacing: 0.005em;
                color: #3D3D3D;
            }

            .margin-left{
                margin-left: 20px;
            }

        

            .result-container{
                min-width: 292px;
                width: fit-content;
                padding-right: 14px;
                height: 77px;
                border-radius: 14px;
                display:flex;
                flex-direction: column;
                justify-content: center;

                .pass{
                    display: flex;
                    flex-direction: row;
                    margin-left: 20px;
                }

                .pass-text{
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 124.4%;   
                    color: #0B8701;
                }

                .percentage-text{
                    font-family: 'Poppins';
                    font-style: normal;
                    font-size: 14px;
                    line-height: 124.4%;
                    letter-spacing: 0.005em;
                    color: #494949;
                }

                .percent-margin{
                    margin-top: 5px;
                    margin-left: 10px;
                }

                .ans-margins{
                    margin-left:19px;
                    margin-top: 2px;
                }

                .bold-600{
                    font-weight: 600;
                }

                .bold-400{
                    font-weight: 400;
                }

                .ml-10{
                    margin-left: 10px;
                }

                .green-text{
                    color: #0B8701;
               }
               
               .red-text{
                    color: #BC3B12;
               }
            }

            .start-time-container{
                width: 228px;
                height: 77px;
                background: #F3F5FA;
                border-radius: 14px;
                margin-left: 10px;
                display:flex;
                flex-direction: column;
                justify-content: center;

                .time{
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 124.4%;
                    letter-spacing: 0.005em;
                    color: #3D3D3D;
                }

                .font-12{
                    font-size: 12px;
                }

            }

            .duration-container{
                width: 228px;
                height: 77px;
                background: #F3F5FA;
                border-radius: 14px;
                margin-left: 10px;
                display:flex;
                flex-direction: column;
                justify-content: center;

                .duration-text{
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 25px;
                    line-height: 124.4%;            
                    color: #3D3D3D;
                }

                .font-17{
                    font-size: 22px;
                    font-weight: 500;
                }
            }


        }
    }


    .divider{
        margin-left: -41px;
        margin-right: -41px;
    }

    .report-body{
           padding-bottom: 20px;

           .qustn-box{
            max-width: 600px;
            pre{
                white-space: pre-wrap;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                line-height: 124.4%; 
                letter-spacing: 0.005em;
                color: #3D3D3D;
                word-wrap: break-word;
                overflow: hidden;
            }
           }

           .question-text{
                font-family: 'Poppins';
                font-style: normal;
                font-size: 13px;
                line-height: 124.4%;  
                letter-spacing: 0.005em;
                color: #3D3D3D;
           }

           .code-box{
                width: 95%;
                max-width: 600px;
                max-height: fit-content;
                background: #F5F5F5;
                border-radius: 4px;
                margin: 10px 25px;
                padding: 10px 16px;
                word-break: break-all;
           }
           .code-text > pre {
            overflow: hidden;
            white-space: pre-wrap;
           }
           .code-text > pre > code{
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 13px;
            padding: 10px;
           }

           .image-box {
            margin: 10px 25px;

            .image-style {
                width: 400px;
            }
            }

            .int-answer {
                margin-left: 25px;
            }

           .right-tick {
               margin-left: 10px;
               height: 10px;
           }
           
           .font-600{
            font-weight: 600;
           }

           .font-400{
            font-weight: 400;
           }

           .mt-11{
            margin-top: -11px;
           }

           .qstn-result{
                     display:flex;
                     justify-content: center;
                     align-items: center;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 11px;
                    line-height: 16px;   
                    letter-spacing: 0.025em;
                    color: #FAFBFF;
                    float: right;
           }

           .green-text{
                color: #47AC45;
           }
           .gold-text{
                color: #C9B861;
           }
           .red-text{
                color: #BC3B12;
           }

           .ps-option-box{
            margin-top: 16px;
            word-wrap: break-word;
            * {
                padding: revert;
            }
            pre{
                white-space: pre-wrap;
                word-wrap: break-word;
                font-family: 'Poppins';
                font-style: normal;
                font-size: 13px;
                line-height: 124.4%;  
                letter-spacing: 0.005em;
                color: #3D3D3D;
                overflow: hidden;
                margin-bottom: 5px;
            }

           }

           .unattempted{
                background: #3D3D3D;
                border-radius: 6px;
                width: 102px;
                height: 19px;
           }

           .correct{
                width: 60px;
                height: 19px;
                background: #0B8701;
                border-radius: 6px;
           }

           .incorrect{
                    background: #CC0F0F;
                    border-radius: 6px;
                    width: 70px;
                    height: 19px;
           }

           .mw-900{
            max-width: 900px;
           }    
    }
}
